import React from 'react';
import styled from 'styled-components';
import { LogoProps } from './types';

export function Comment({ width, height, color }: LogoProps) {
  const CommentSvg = styled.svg`
    path {
      transition: fill 0.3s ease-in-out;
    }
  `;

  return (
    <CommentSvg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25" fill="none">
      <path
        d="M20.833 2.08301H4.16634C3.02051 2.08301 2.08301 3.02051 2.08301 4.16634V22.9163L6.24967 18.7497H20.833C21.9788 18.7497 22.9163 17.8122 22.9163 16.6663V4.16634C22.9163 3.02051 21.9788 2.08301 20.833 2.08301ZM20.833 16.6663H6.24967L4.16634 18.7497V4.16634H20.833V16.6663Z"
        fill={color}
      />
    </CommentSvg>
  );
}

export default Comment;
