import React from 'react';
import { LogoProps } from './types';

function ArrowPlainDown({ width, height, color }: LogoProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 7L6.795 6.295L4.5 8.585V0.5H3.5V8.585L1.205 6.29L0.5 7L4 10.5L7.5 7Z" fill={color} />
    </svg>
  );
}

export default ArrowPlainDown;
