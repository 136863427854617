import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';

export const Container = styled.div`
  display: grid;
  grid-column: 2 / 3;
  width: 100%;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;
      flex-direction: initial;
      grid-column: 1 / 10;
      grid-template-columns: 60px 7fr 60px 60px 10fr 60px 60px 7fr 60px;
    `,
  )}
`;

export const MainContent = styled.div`
  width: 100%;
`;

export const SideContent = styled.aside`
  display: none;
  width: 300px;
  flex-shrink: 0;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;
      flex-direction: column;
      margin-left: 30px;
    `,
  )}
`;
