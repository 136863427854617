import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

export type TabletProps = {
  className?: string;
  children: ReactNode;
};

const Tablet = styled.span`
  background: var(--color-alt-5);
  border-radius: 3px;
  color: var(--color-alt-1);
  display: inline-block;
  font-size: 13px;
  line-height: 20px;
  transition: all 0.3s;

  &:hover {
    background: var(--theme-color-primary);
    color: white;

    &.mod-without-link {
      background: var(--color-alt-5);
      color: var(--color-alt-1);
    }
  }

  a {
    display: block;
    padding: 4px 10px;
  }

  &.mod-without-link {
    padding: 4px 10px;
  }

  &.mod-blue {
    background: ${(props) => (props.theme.isDark ? props.theme.palette.darkBlack : props.theme.palette.blue)};
    color: white;

    &:hover {
      background: var(--theme-palette-dark-blue);
    }
  }
`;

export function Tag({ className, children }: PropsWithChildren<TabletProps>) {
  return <Tablet className={className}>{children}</Tablet>;
}
