import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { MainContent } from '../../atoms/container';

const Section = styled.div`
  width: 100%;
`;

const SpacedMainContent = styled(MainContent)`
  display: flex;
  flex-flow: wrap;

  &.mod-faded {
    animation: fade-in 1s;

    @keyframes fade-in {
      0%,
      80% {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      justify-content: space-between;
    `,
  )}
`;

interface Props {
  faded?: boolean;
  renderAfterEachChild?: (index: number) => React.ReactElement;
  'data-testid'?: string;
}

export function PostsList({
  children,
  renderAfterEachChild,
  'data-testid': dataTestId,
  faded,
}: React.PropsWithChildren<Props>) {
  return (
    <SpacedMainContent className={faded ? 'mod-faded' : ''} data-testid={dataTestId}>
      {React.Children.toArray(children)
        .filter(Boolean)
        .map((child, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <Section>{child}</Section>
            {renderAfterEachChild ? renderAfterEachChild(index) : null}
          </React.Fragment>
        ))}
    </SpacedMainContent>
  );
}
