import { useRef } from 'react';
import { useInView } from 'react-intersection-observer';

export const useInfiniteScroll = (handleBecameVisible: () => void) => {
  const arriveInView = useRef<boolean>(false);
  const { ref, inView } = useInView({
    rootMargin: '75%',
  });

  if (inView && !arriveInView.current) {
    arriveInView.current = true;
    handleBecameVisible();
  }

  if (!inView) {
    arriveInView.current = false;
  }

  return ref;
};
