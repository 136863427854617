import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { useImageComponent, useLinkComponent } from '../utils/component';
import { useTranslation } from '../utils/translation';

const StyledImage = styled.div`
  width: 100%;
  height: auto;
  flex-shrink: 0;
`;

const Container = styled.div`
  position: relative;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;
      flex-direction: column;
      width: auto;
    `,
  )}
`;

const Title = styled.p`
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.3) 50px, rgba(0, 0, 0, 0.4) 100%);
  border-radius: 0 0 6px 6px;
  color: var(--theme-palette-white);
  font-family: var(--theme-typography-primary-font);
  font-size: 18px;
  font-weight: bold;
  left: 0;
  line-height: 1.62;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  padding: var(--spacer-fluid);
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      border-radius: 0 0 10px 10px;
      font-size: 22px;
      line-height: 1.38;
      overflow: hidden;
    `,
  )}
`;

const LeftSide = styled.div`
  position: relative;
`;

const Sponsored = styled.div`
  bottom: 3px;
  color: white;
  font-size: 11px;
  position: absolute;
  right: 3px;
  z-index: 1;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      bottom: 7px;
      font-size: 12px;
      right: 7px;
    `,
  )}
`;

interface HeroProps {
  url?: string;
  image?: string;
  title?: string;
  tagSlugs?: string[];
}

export const TertiaryHero = ({ title, url, image, tagSlugs }: HeroProps) => {
  const LinkComponent = useLinkComponent();
  const ImageComponent = useImageComponent();
  const t = useTranslation();

  const isSponsoredItem = tagSlugs?.includes('sponsored');

  const imageProps = {
    imageId: image,
    alt: title,
    lazy: false,
    mobileWidth: 240,
    mobileHeight: 240,
    desktopWidth: 270,
    desktopHeight: 300,
  };

  return (
    <Container>
      <LinkComponent href={url}>
        <LeftSide>
          <StyledImage>
            <ImageComponent {...imageProps} />
          </StyledImage>
          <Title>{title}</Title>
        </LeftSide>
      </LinkComponent>
      {isSponsoredItem && <Sponsored>{t(`design.sponsored`)}</Sponsored>}
    </Container>
  );
};
