import React from 'react';
import { LogoPropsOptionalColor } from './types';

function Clubic({ width, height }: LogoPropsOptionalColor) {
  return (
    <svg
      width={width}
      height={height}
      fillRule="evenodd"
      strokeMiterlimit="10"
      clipRule="evenodd"
      viewBox="0 0 1643 876"
      aria-hidden="true"
    >
      <title>Retour accueil Clubic</title>
      <path
        fill="#ff0037"
        fillRule="nonzero"
        stroke="#ff0037"
        strokeWidth="4.17"
        d="M1539.78 854.271H101.05c-44.063 0-80.113-36.05-80.113-80.121V101.062c0-44.071 36.054-80.125 80.125-80.125h1440.06c44.062 0 80.116 36.059 80.116 80.125 0 0-1.054 479.313-1.333 673.225-.067 44.204-35.921 79.984-80.125 79.984z"
      />
      <path
        fill="var(--theme-palette-white)"
        fillRule="nonzero"
        d="M734.973 496.486c0 33.288-29.458 47.963-47.962 47.963-22.367 0-47.958-16.983-47.958-47.963V338.999h-59.459s.446 99.296.446 157.487c0 55.992 46.271 95.963 95.921 95.963 35.792 0 59.012-20.308 59.012-20.308v16.112h59.459V338.999h-59.459v157.487zm-417.592 36.507c-40.921 0-73.567-35.437-68.925-77.295 3.437-31.021 27.996-56.409 58.904-60.734 19.221-2.691 37.2 2.504 51.167 12.817 10.804 7.975 27.704 8.087 38.083-.429l24.504-20.117c-24.579-33.321-64.821-54.375-109.858-52.292-64.738 3-117.925 54.896-122.383 119.546-5.188 75.284 54.337 137.963 128.508 137.963 38.375 0 72.829-16.792 96.429-43.417l-19.15-22.012c-9.871-11.342-26.617-13.721-39.171-5.442-10.933 7.212-24.029 11.412-38.108 11.412"
      />
      <path
        fill="var(--theme-palette-white)"
        d="M1136.38 253.917h59.458v44.875h-59.458zm0 85.079h59.458v249.258h-59.458z"
      />
      <path
        fill="var(--theme-palette-white)"
        fillRule="nonzero"
        d="M1366.61 532.993c-39.55 0-71.367-33.095-69.271-73.1 1.796-34.233 29.275-62.604 63.437-65.395 16.296-1.334 31.53 2.975 43.967 11.179 12.896 8.508 30 6.55 40.142-5.104l18.808-21.621c-24.567-28.146-61.171-45.513-101.787-44.063-65.3 2.325-119.309 54.417-123.805 119.6-5.191 75.279 54.338 137.963 128.509 137.963 38.733 0 73.466-17.1 97.083-44.154l-18.817-21.63c-10.025-11.516-27.054-13.845-39.745-5.354-11.017 7.379-24.267 11.679-38.521 11.679m-399.579 6.702c-36.671 0-66.404-34.058-66.404-76.071 0-42.012 29.733-76.071 66.404-76.071 36.675 0 66.404 34.059 66.404 76.071 0 42.013-29.729 76.071-66.404 76.071m10.125-204.892c-25.358 0-48.833 8.842-68.017 23.871V253.92h-59.458v334.333h59.458v-19.675c19.184 15.03 42.659 23.871 68.017 23.871 64.771 0 117.275-57.679 117.275-128.825 0-71.146-52.504-128.821-117.275-128.821"
      />
      <path fill="var(--theme-palette-white)" d="M464.203 253.917h59.458v334.337h-59.458z" />
    </svg>
  );
}

export default Clubic;
